*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}



.custom-drop-btn {
  background: #130360;
  padding: 10px 40px;
  border-radius: 5%;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: #fff;
  background-color: #bce4ea !important;
  min-height: 100vh;
}

a {
  color: white;
  text-decoration: none;
}

.header-dropdown {
  padding-right: 5em;
}

.dark-nav {
  background-color: #130360;
  color: #fff;
  min-height: 64px;
}

.header-logo {
  width: 40%;
}

.header-social {
  margin-top: 20px;
  width: 60%;
}

.main-area {
  color: #fff;
  background-color: #bce4ea;
}

.card-content-area {
  width: 700px;
  height: auto;
  background: linear-gradient(90deg, #0c1a39 0%, #74b0b8 93%);
}

.footer-area {
  background: #130360;
  color: #fff;
  border-radius: 40px 40px 0px 0px;
  font-size: 1.5em;
}

.footer-map {
  --bs-aspect-ratio: 20%;
}

.footer-icon {
  color: #fff;
  font-size: 1.5em;
}

.footer-adress {
  width: 30%;
}

.category-card {
  width: 230px;
  height: 280px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: border 0.3s ease-in-out;
}

.category-card:hover {
  border: 3px solid #d32f2f;
}

@keyframes phone-animation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(30deg);
  }
}

.header-social .phone-icon {
  animation-name: phone-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.category-slider {
  padding-bottom: 50px;
}

.main-slider {
  width: 70%;
  height: 500px;
  margin-right: auto; 
  margin-left: auto; 
  padding-top: 5%; 
  margin-bottom:10%;
  color: #fff;
}

  .main-slider-card {
  height: auto;
} 

.main-slider-card img {
  width: 100%;
  object-fit: cover;
  height: 500px;
}  

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  transition: opacity .4s ease visibility .4s ease transfom .5s ease-in-out;
  visibility: hidden;
  transform: scale(0);
  overflow: hidden;
}

.modal-img {
  width: 100%;
    width: -moz-available;          
    width: -webkit-fill-available;  
    width: fill-available;
}

/* .model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
} */

@media screen and (max-width: 1300px) {
  logo-img {
    width: 100%;
    height: auto;
  }
  .main-slider {
    width: 70%;
    height: 400px;
  }

  .main-slider-card {
    height: 400px;
  }

  .main-slider-card img {
    height: 400px;
  }  
  
}

@media screen and (max-width: 920px) {
  .header-logo {
    width: 90%;
  }

  .header-social {
    margin-top: 0;
    width: 50%;
  }

  .header-dropdown {
    margin-top: 20px;
    padding-right: 0;
  }

  .footer-map {
    --bs-aspect-ratio: 20%;
  }

  .footer-icon {
    font-size: 1.5em;
    width: fit-content;
  }

  .footer-adress {
    width: 100%;
  }
  .footer-phone-social {
    width: 100%;
  }
  .main-slider {
    width: 70%;
    height: 350px;
  }

  .main-slider-card {
    height: 350px;
  }

  .main-slider-card img {
    height: 350px;
  }  

}

logo-img {
  width: 80%;
  height: fit-content;
}



@media screen and (max-width: 768px) {
  .footer-area {
    font-size: 1.2em;
  }
  .footer-map {
    --bs-aspect-ratio: 35%;
  }

  .main-slider {
    width: 70%;
    height: 300px;
  }

  .main-slider-card img {
    height: 300px;
  }  

  .main-slider-card h5 {
    font-size: 1em;
  }
  .main-slider-card p {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 576px) {
  .footer-area {
    font-size: 0.8em;
  }

  .footer-map {
    --bs-aspect-ratio: 60%;
  }
  .main-slider {
    width: 70%;
    height: 250px;
  }

  .main-slider-card {
    height: 250px;
  }

  .main-slider-card img {
    height: 250px;
  }  
}
